import React from "react";
import { graphql } from "gatsby";
import { PageLayout } from "../components/PageLayout";
import { Landing } from "../components/Landing";
import { ContentMenu } from "../components/ContentMenu";
import { Categories } from "../components/home/Categories";
import { Events } from "../components/home/Events";
import { HeroText } from "../components/home/HeroText";
import { HomeBlog } from "../components/home/News";

interface IndexPageTemplateProps {
  heroText1: string;
  heroText2: string;
  heroText3: string;
}

const IndexPageTemplate: React.FC<IndexPageTemplateProps> = ({ heroText1, heroText2, heroText3 }) => (
  <div>
    <Landing />
    <ContentMenu />
    <HeroText text={heroText1} />
    <Categories />
    <Events />
    <HeroText text={heroText2} />
    <HomeBlog />
    <HeroText text={heroText3} />
  </div>
);

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <PageLayout>
      <IndexPageTemplate
        heroText1={frontmatter.heroText1}
        heroText2={frontmatter.heroText2}
        heroText3={frontmatter.heroText3}
      />
    </PageLayout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heroText1
        heroText2
        heroText3
      }
    }
  }
`;
