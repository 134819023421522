import { format, isBefore, set } from "date-fns";

export function isMoreDay(dateFrom: Date, dateTo: Date) {
  return dateFrom.getDate() !== dateTo.getDate();
}

export function formatDate(dateFrom: Date, dateTo: Date) {
  if (isMoreDay(dateFrom, dateTo)) {
    return `${format(dateFrom, "dd")}. - ${format(dateTo, "dd.M.yyyy")}`;
  }
  return format(dateTo, "dd.M.yyyy");
}

export function formatTime(dateFrom: Date, dateTo: Date) {
  return `${format(dateFrom, "H.mm")}-${format(dateTo, "H.mm")}`;
}

function dateFromDateTime(datetime: Date) {
  return set(datetime, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
}

export function isOlderThanToday(date: Date) {
  const resourceDate = dateFromDateTime(date);
  const todayDate = dateFromDateTime(new Date());
  return isBefore(resourceDate, todayDate);
}
