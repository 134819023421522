import React from "react";
import scrollToEl from "gatsby-plugin-smoothscroll";
import Logo from "../img/Logo_CHDH.svg";

export const ContentMenu: React.FC = () => {
  return (
    <div className="content-menu" id="content-menu">
      <div className="container">
        <div className="content-menu-wrapper">
          <div className="content-menu-logo mouse-observer">
            <Logo />
          </div>
          <div className="content-menu-link" onClick={() => scrollToEl("#events")}>
            události
          </div>
          <div className="content-menu-link" onClick={() => scrollToEl("#blog")}>
            aktuality
          </div>
          <div className="content-menu-link" onClick={() => scrollToEl("#contact")}>
            kontakt
          </div>
        </div>
      </div>
    </div>
  );
};
