import React, { useContext } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import Arrow from "../../img/arrow.svg";
import { AppContext } from "../../contexts/AppContext";

interface BlogQueryResult {
  title: string;
  imageFluid: FluidObject;
  date: Date;
  description: string;
  slug: string;
}

function useDataFetch(): BlogQueryResult[] {
  const queryData = useStaticQuery(graphql`
    query BlogQueryHome {
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "blog" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 3
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 40) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
            description
          }
        }
      }
    }
  `);

  return queryData.allMarkdownRemark.nodes.map((node) => {
    const { title, image, date, description } = node.frontmatter;
    return {
      title,
      imageFluid: image?.childImageSharp?.fluid,
      date: new Date(date),
      description,
      slug: node.fields.slug,
    };
  });
}

export const HomeBlog = () => {
  const data = useDataFetch();
  const { isLaptop, isMobile } = useContext(AppContext);

  return (
    <>
      <div className="container" id="blog">
        <h2 className="section-header green">aktuality</h2>
      </div>
      <div className="news blue-home-section">
        <div className="container">
          <div className="home-news">
            {data
              .filter((_, i) => (isLaptop ? i < 2 : true))
              .map((n, i) => (
                <Link to={n.slug} className="card news-card" key={i}>
                  <div className="card-title">{n.title}</div>
                  <div className="card-image">
                    <Img fluid={n.imageFluid} alt={n.title} />
                  </div>
                  <div className="card-description">{n.description}</div>
                  <div className="card-overlay">
                    <div className="card-overlay-link">Přečíst</div>
                  </div>
                </Link>
              ))}
          </div>
          <Link to="/blog" className="circle-btn news-archive-btn">
            <Arrow />
          </Link>
          {/* <div className="news-pen">
              <Pen />
            </div> */}
        </div>
      </div>
    </>
  );
};
