import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React, { useContext } from "react";
import Carousel from "nuka-carousel";
import { truncate } from "lodash";
import { formatDate, formatTime, isMoreDay, isOlderThanToday } from "../../lib/dateUtil";
import Arrow from "../../img/carousel_arrow.svg";
import { AppContext } from "../../contexts/AppContext";

interface EventQueryResult {
  title: string;
  imageFluid: FluidObject;
  dateFrom: Date;
  dateTo: Date;
  location: string;
  city: string;
  description: string;
  link: string;
}

function useDataFetch() {
  const queryData = useStaticQuery(graphql`
    query EventsQuery {
      # TODO: find a way to filter old events in the graphql query
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "event" } } }
        sort: { fields: frontmatter___dateFrom, order: DESC }
      ) {
        nodes {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 40) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            dateFrom
            dateTo
            location
            city
            description
            link
          }
        }
      }
    }
  `);

  const data: EventQueryResult[] = queryData.allMarkdownRemark.nodes.map((node) => ({
    title: node.frontmatter.title,
    imageFluid: node.frontmatter?.image?.childImageSharp?.fluid,
    dateFrom: new Date(node.frontmatter.dateFrom),
    dateTo: new Date(node.frontmatter.dateTo),
    location: node.frontmatter.location,
    city: node.frontmatter.city,
    description: node.frontmatter.description,
    link: node.frontmatter.link,
  }));
  return data.filter((event) => !isOlderThanToday(event.dateTo || event.dateFrom));
}

export const Events = () => {
  const data = useDataFetch();
  const { isLaptop, isTablet, isMobile } = useContext(AppContext);

  let slidesCount = 4;
  if (isLaptop) slidesCount = 3;
  if (isTablet) slidesCount = 2;
  if (isMobile) slidesCount = 1;

  return (
    <>
      <div className="container" id="events">
        <h2 className="section-header yellow">události</h2>
      </div>
      <div className="events blue-home-section">
        <div className="events-wrapper">
          <Carousel
            cellAlign="left"
            slidesToShow={slidesCount}
            cellSpacing={16}
            dragging={false}
            defaultControlsConfig={{
              pagingDotsStyle: { display: "none" },
              nextButtonStyle: { display: "none" },
              prevButtonStyle: { display: "none" },
            }}
            heightMode="current"
            renderTopLeftControls={({ previousSlide, nextSlide, currentSlide, slideCount, slidesToShow }) => (
              <div className="event-controls">
                <button
                  onClick={previousSlide}
                  type="button"
                  className={currentSlide === 0 || data.length <= slidesCount ? "inactive" : ""}
                >
                  <Arrow />
                </button>
                <button
                  onClick={nextSlide}
                  type="button"
                  className={
                    currentSlide + slidesToShow === slideCount || data.length <= slidesCount ? "inactive" : ""
                  }
                >
                  <Arrow />
                </button>
              </div>
            )}
          >
            {data.map((result, i) => (
              <a className="card event-card" key={i} href={result.link} target="_blank" rel="noreferrer">
                <div className="card-title">{result.title}</div>
                {!isMobile && (
                  <div className="card-image">
                    <Img fluid={result.imageFluid} alt={result.title} />
                  </div>
                )}
                <div className="card-dates">
                  <span>{formatDate(result.dateFrom, result.dateTo)}</span>
                  {!isMoreDay(result.dateFrom, result.dateTo) && (
                    <span>{formatTime(result.dateFrom, result.dateTo)}</span>
                  )}
                </div>
                <div className="card-location">
                  <div>{result.location}</div>
                  <div>{result.city}</div>
                </div>
                <div className="card-desc">{truncate(result.description, { length: 360 })}</div>
                <div className="card-overlay">
                  <div className="card-overlay-link">Přejít na událost</div>
                </div>
              </a>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};
